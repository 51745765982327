import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import querystring from "querystring";
import { TwoColumnRightSidebar } from "../../layouts";
import { isDefined } from "../../../utils/ObjectUtils";
import {
  isDataLayerLoaded,
  initializeGTM
} from "../../../utils/googleTagManager";
import { widgetActions } from "../../../state/widget";
import { questionActions } from "../../../state/question";
import { locationActions } from "../../../state/location";
import { trackActions } from "../../../state/track";
import { leadActions } from "../../../state/lead";
import StepOneQuestions from "./StepOneQuestions";
import StepTwoQuestions from "./StepTwoQuestions";
import StepThreeQuestions from "./StepThreeQuestions";
import MatchResults from "./MatchResults";
// import MatchStageTwo from "./MatchStageTwoV2";
import MatchRedirect from "./MatchRedirect";
import {
  searchingSchoolMessage,
  submittingMatchesMessage,
  categoryMap,
  fourOFourErrorMessage,
  invalidZipErrorMessage,
  pixelEventName,
  snapchatPixelEventName
} from "../../../config";
import { isFbpLoaded, fbpInit } from "../../../utils/fbPixel";

class HomePage extends Component {
  state = {
    isQuestionGroupCreated: false,
    isLeadCreated: false,
    universalLeadId: "",
    categoryId: null,
    selectedCategoryIds: null,
    questionsGroup: {
      step1: [],
      step2: [],
      step3: [],
      step4: [],
      step5: []
    },
    searchingSchoolMessageIndex: 0
  };
  messageInterval = null;

  startMessageInterval = () => {
    if (this.messageInterval) {
      clearInterval(this.messageInterval);
    }

    this.messageInterval = setInterval(() => {
      this.setState(prevState => ({
        searchingSchoolMessageIndex:
          (prevState.searchingSchoolMessageIndex + 1) %
          searchingSchoolMessage.length
      }));
    }, 3000);
  };

  stopMessageInterval = () => {
    if (this.messageInterval) {
      clearInterval(this.messageInterval);
      this.messageInterval = null;
    }
  };

  componentDidMount() {
    const {
      fetchWidgetById,
      fetchAllQuestions,
      fetchLocationByZip,
      history,
      location,
      trackPixel,
      trackSnapchatPixel
    } = this.props;

    isDataLayerLoaded()
      .then(() => {
        initializeGTM();
      })
      .catch(error => {
        console.log(error);
      });

    const queries = querystring.parse(location.search.substring(1));
    const widgetId = queries.widget;
    const zip = queries.zip;
    const referralUrl =
      queries.refURL || document.referrer || "https://supportivecolleges.com/";
    const schoolType = queries.schoolType || "Both";
    const campusCode = queries.campusCode || "";

    fbpInit(referralUrl);

    isFbpLoaded()
      .then(() => {
        trackPixel({
          step: "1",
          eventName: pixelEventName.pageView,
          leadData: {
            referralURL: referralUrl,
            zip
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
    trackSnapchatPixel({
      step: "1",
      eventName: snapchatPixelEventName.pageView,
      eventTag: "Lead Conversion",
      leadData: {
        referralURL: referralUrl,
        zip
      }
    });

    if (typeof queries.categoryId === "string") {
      queries.categoryId = [queries.categoryId];
    }

    this.setState({
      referralUrl,
      schoolType,
      queries,
      selectedCategoryIds: queries.categoryId,
      campusCode
    });

    if (zip) {
      fetchLocationByZip(zip);
    } else {
      history.push(
        `/error?message=${fourOFourErrorMessage}&redirectUrl=${referralUrl}`
      );
    }

    if (widgetId) {
      fetchAllQuestions();
      fetchWidgetById(widgetId);

      let intervalId = setInterval(() => {
        const leadIdInput = document.getElementById("leadid_token");
        if (leadIdInput && leadIdInput.value) {
          this.setState({ universalLeadId: leadIdInput.value });
        }
      }, 2000);

      this.setState({ intervalId: intervalId });
    } else {
      history.push(
        `/error?message=${fourOFourErrorMessage}&redirectUrl=${referralUrl}`
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      fetchAllQuestionsDone,
      allQuestions,
      widgetData,
      createLead,
      createLeadDone,
      createLeadFailed,
      fetchWidgetByIdFailed,
      isFetchingWidgetById,
      isFetchingLocationByZip,
      fetchLocationByZipDone,
      fetchLocationByZipFailed,
      history,
      searchFeedOptions,
      locationData,
      isSearchingMatches
    } = this.props;
    const {
      isQuestionGroupCreated,
      isLeadCreated,
      intervalId,
      universalLeadId,
      referralUrl,
      queries
    } = this.state;

    if (isSearchingMatches && !prevProps.isSearchingMatches) {
      this.setState({ searchingSchoolMessageIndex: 0 }, () => {
        this.startMessageInterval();
      });
    } else if (!isSearchingMatches && prevProps.isSearchingMatches) {
      this.stopMessageInterval();
    }

    if (widgetData && !this.state.categoryId) {
      let categoryId = queries.categoryId;
      let mappedId = [];
      let localMappedId = [];

      for (let i = 0; i < categoryId.length; i++) {
        localMappedId = [...localMappedId, ...categoryMap[categoryId[i]]];
      }
      let localCategoryId = [...new Set(localMappedId)];
      searchFeedOptions("program", localCategoryId);

      for (let i = 0; i < categoryId.length; i++) {
        if (
          widgetData.categoryMapping &&
          widgetData.categoryMapping[categoryId[i]]
        ) {
          mappedId = [
            ...mappedId,
            ...widgetData.categoryMapping[categoryId[i]]
          ];
        } else {
          mappedId = [...mappedId, ...categoryMap[categoryId[i]]];
        }
      }
      // Move user selected category to first position
      categoryId = [...new Set(mappedId)].sort((x, y) =>
        x === +categoryId[0] ? -1 : y === +categoryId[0] ? 1 : 0
      );
      this.setState({ categoryId });
    }

    if (fetchAllQuestionsDone && !isQuestionGroupCreated) {
      let questionsGroup = {
        step1: [],
        step2: [],
        step3: [],
        step4: [],
        step5: []
      };

      allQuestions.sort((q1, q2) => q1.orderNo - q2.orderNo);

      for (let i = 0; i < allQuestions.length; i++) {
        questionsGroup[`step${allQuestions[i].stepNo}`].push(allQuestions[i]);
      }
      this.setState({ questionsGroup, isQuestionGroupCreated: true });
    }

    if (
      !isLeadCreated &&
      universalLeadId &&
      !createLeadDone &&
      !createLeadFailed
    ) {
      createLead({
        universalLeadId,
        widgetId: widgetData.id,
        referralUrl: referralUrl || widgetData.landingPageUrl
      });
      this.setState({ isLeadCreated: true });
      clearInterval(intervalId);
    }

    if (!isFetchingWidgetById && fetchWidgetByIdFailed) {
      history.push(
        `/error?message=${fourOFourErrorMessage}&redirectUrl=${referralUrl}`
      );
    }

    if (
      !isFetchingLocationByZip &&
      (fetchLocationByZipFailed || (fetchLocationByZipDone && !locationData))
    ) {
      history.push(
        `/error?message=${invalidZipErrorMessage}&redirectUrl=${referralUrl}`
      );
    }
  }

  render() {
    const {
      questionsGroup,
      universalLeadId,
      categoryId,
      schoolType,
      selectedCategoryIds,
      campusCode,
      searchingSchoolMessageIndex
    } = this.state;
    const {
      isFetchingWidgetById,
      widgetData,
      isFetchingAllQuestions,
      isSearchingFeedOptions,
      isFetchingLocationByZip,
      isCompletingLead,
      currentStep,
      isSearchingMatches,
      showSearchingLoader,
      isSubmittingMatches,
      // stageTwoSearch,
      isSearchingClickoutsMatches,
      allMatches
    } = this.props;

    return (
      <TwoColumnRightSidebar
        header={
          isDefined(widgetData, `staticContents.${currentStep - 1}.header`)
            ? widgetData.staticContents[currentStep - 1].header
            : ""
        }
        sidebar={
          allMatches.count < 1 && currentStep === 4
            ? "none"
            : isDefined(widgetData, `staticContents.${currentStep - 1}.sidebar`)
            ? widgetData.staticContents[currentStep - 1].sidebar
            : ""
        }
        footer={
          isDefined(widgetData, `staticContents.${currentStep - 1}.footer`)
            ? widgetData.staticContents[currentStep - 1].footer
            : ""
        }
        isPageLoading={isFetchingWidgetById || isFetchingAllQuestions}
        isDataLoading={
          !universalLeadId ||
          isSearchingFeedOptions ||
          isFetchingLocationByZip ||
          isCompletingLead ||
          isSearchingMatches ||
          isSubmittingMatches ||
          isSearchingClickoutsMatches
        }
        showCircular={!isSubmittingMatches && showSearchingLoader}
        loadingMessage={
          isSearchingMatches || isSearchingClickoutsMatches
            ? searchingSchoolMessage[searchingSchoolMessageIndex]
            : isSubmittingMatches
            ? submittingMatchesMessage
            : ""
        }
      >
        {currentStep === 1 && (
          <StepOneQuestions
            questions={questionsGroup[`step${currentStep}`]}
            categoryId={categoryId}
            schoolType={schoolType}
            campusCode={campusCode}
            selectedCategoryIds={selectedCategoryIds}
            widgetData={widgetData}
          ></StepOneQuestions>
        )}

        {currentStep === 2 && (
          <StepTwoQuestions
            questions={questionsGroup[`step${currentStep}`]}
          ></StepTwoQuestions>
        )}

        {currentStep === 3 && (
          <StepThreeQuestions
            questions={questionsGroup[`step${currentStep}`]}
          ></StepThreeQuestions>
        )}

        {currentStep === 4 && allMatches.count < 1 && <MatchRedirect />}

        {currentStep === 4 && allMatches.count > 0 && <MatchResults />}

        <input
          type="hidden"
          id="leadid_token"
          name="universal_leadid"
          value=""
        />
      </TwoColumnRightSidebar>
    );
  }
}

const mapStateToProps = ({ widget, question, lead, location, match }) => {
  return {
    isFetchingWidgetById: widget.isFetchingWidgetById,
    fetchWidgetByIdDone: widget.fetchWidgetByIdDone,
    fetchWidgetByIdFailed: widget.fetchWidgetByIdFailed,
    widgetData: widget.widgetData,
    isFetchingAllQuestions: question.isFetchingAllQuestions,
    fetchAllQuestionsDone: question.fetchAllQuestionsDone,
    allQuestions: question.allQuestions,
    isSearchingFeedOptions: question.isSearchingFeedOptions,
    feedOptions: question.feedOptions,
    isFetchingLocationByZip: location.isFetchingLocationByZip,
    fetchLocationByZipDone: location.fetchLocationByZipDone,
    fetchLocationByZipFailed: location.fetchLocationByZipFailed,
    locationData: location.locationData,
    isCreatingLead: lead.isCreatingLead,
    createLeadDone: lead.createLeadDone,
    createLeadFailed: lead.createLeadFailed,
    leadData: lead.leadData,
    isCompletingLead: lead.isCompletingLead,
    currentStep: question.currentStep,
    isSearchingMatches: match.isSearchingMatches,
    showSearchingLoader: match.showSearchingLoader,
    isSubmittingMatches: match.isSubmittingMatches,
    // stageTwoSearch: question.stageTwoSearch,
    allMatches: match.allMatches,
    isSearchingClickoutsMatches: match.isSearchingClickoutsMatches
  };
};

const mapActionsToProps = {
  fetchWidgetById: widgetActions.fetchWidgetById,
  fetchLocationByZip: locationActions.fetchLocationByZip,
  fetchAllQuestions: questionActions.fetchAllQuestions,
  searchFeedOptions: questionActions.searchFeedOptions,
  createLead: leadActions.createLead,
  updateLead: leadActions.updateLead,
  trackPixel: trackActions.trackPixel,
  trackSnapchatPixel: trackActions.trackSnapchatPixel
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(HomePage));

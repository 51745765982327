import React, { useState } from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { LogoDisplayFormat } from "./index";

const CardWrapper = styled.div`
  border-bottom: 1px solid #c9c9c9;
  margin-bottom: 32px;
  padding-bottom: 32px;
  padding-top: 32px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    font-size: 14px;
    color: #888888;
    line-height: 19px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .school-description {
    h5 {
      font-size: 17px;
      margin: 0;
      font-weight: 600;
      color: #444444;
    }

    p {
      margin-top: 10px;

      .read-more {
        cursor: pointer;
        font-weight: 700;
      }
    }
  }

  .cta_article_link {
    font-weight: 600;
    color: #f1440d;
  }
`;

const Description = ({ description, url, searchId }) => {
  const [showFullText, setShowFullText] = useState(false);

  const text = description ? description.replace(/(<([^>]+)>)/gi, "") : "";
  return (
    <p>
      {text.length > 300 && !showFullText ? (
        <>
          {text.slice(0, 300)}

          {!searchId && (
            /* eslint-disable */
            <a className="read-more" href={url} target="_blank" rel="noopener">
              ... Read More
            </a>
            /* eslint-enable */
          )}
        </>
      ) : text.length > 300 && showFullText ? (
        <>
          {text}
          <span
            className="read-more"
            onClick={() => {
              setShowFullText(false);
            }}
          >
            {" "}
            Show Less
          </span>
        </>
      ) : (
        text
      )}
    </p>
  );
};

const DefaultArticleCard = ({
  articleTitle,
  imageURL,
  description,
  url,
  isFeatured = false
}) => {
  return (
    <CardWrapper className="clicks-school-wrapper">
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4}>
          {/* eslint-disable  */}
          <a href={url} target="_blank" rel="noopener">
            {/* eslint-enable  */}
            <LogoDisplayFormat logoUrl={imageURL} name={articleTitle} />
          </a>
        </Grid>
        <Grid item xs={8} sm={8}>
          <div className="school-description">
            <h5>
              {/* eslint-disable  */}
              <a href={url} target="_blank" rel="noopener">
                {articleTitle}
              </a>
              {/* eslint-enable  */}
            </h5>
            {isFeatured && <span className="school-star"></span>}
            <Description description={description} url={url} />
          </div>
        </Grid>
        <Grid item sm={4} xs={4}>
          {/* <p>Sponsored Results</p> */}
        </Grid>
        <Grid item xs={8} sm={8}>
          <div className="cta_article_link">
            {/* eslint-disable  */}
            <a href={url} target="_blank" rel="noopener">
              READ ARTICLE
            </a>
            {/* eslint-enable  */}
          </div>
        </Grid>
      </Grid>
    </CardWrapper>
  );
};

export default DefaultArticleCard;

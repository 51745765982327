import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";

import { createGTMEvent } from "../../../utils/googleTagManager";
import { pixelEventName, dreamBoundSchoolLogo } from "../../../config";
import { trackActions } from "../../../state/track";
import { BodyContainer, ImageViewer } from "../../components";
class MatchRedirect extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { trackPixel, leadData } = this.props;

    window.scrollTo(0, 0);
    createGTMEvent({
      event: "Step Conversion",
      label: "Step 4 Redirect Page",
      action: "Load"
    });

    trackPixel({
      step: "4",
      eventName: pixelEventName.pageView,
      leadData
    });
    const selectedCategoryId = leadData.selectedCategoryIds[0];
    let redirectUrl = "";
    this.timer = setTimeout(
      selectedCategoryId => {
        this.setState({ loading: false });
        switch (selectedCategoryId) {
          case "1":
            redirectUrl =
              "https://dreambound.com/affiliate/03240037/health?_ef_transaction_id=&uid=1757&oid=1&affid=37"; //Health
            break;

          case "5":
            redirectUrl =
              "https://dreambound.com/affiliate/03240037/business-and-office?_ef_transaction_id=&uid=1759&oid=1&affid=37"; //Business
            break;

          case "6":
            redirectUrl =
              "https://dreambound.com/affiliate/03240037/technical-and-communications?_ef_transaction_id=&uid=1758&oid=1&affid=37"; //Technology
            break;

          case "7":
            redirectUrl =
              "https://dreambound.com/affiliate/03240037/trade-and-industry?_ef_transaction_id=&uid=1760&oid=1&affid=37"; //Trade
            break;

          default:
            redirectUrl =
              "https://dreambound.com/?_ef_transaction_id=&oid=1&affid=37"; //Others
            break;
        }
        window.location.href = redirectUrl;
      },
      5000,
      selectedCategoryId
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { loading } = this.state;
    return (
      <Fragment>
        <BodyContainer>
          <Typography variant="h5" gutterBottom="true">
            Great News! We've Found Some University Matches for You
          </Typography>

          {loading ? (
            <Fragment>
              <Typography paragraph="true" gutterBottom="true">
                We are currently preparing to display your matches. Please stay
                on this page for a moment. We will forward to the results page
                as soon as its ready.
              </Typography>
              <ImageViewer
                imageUrl={dreamBoundSchoolLogo}
                altName="Dream Bound School logo"
              />
            </Fragment>
          ) : (
            <Fragment>
              <Typography paragraph="true" gutterBottom="true">
                Redirecting to the captivating school page now...
              </Typography>
              <ImageViewer
                imageUrl={dreamBoundSchoolLogo}
                altName="Dream Bound School logo"
              />
            </Fragment>
          )}
        </BodyContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ lead }) => {
  return {
    leadData: lead.leadData
  };
};

const mapActionsToProps = {
  trackPixel: trackActions.trackPixel
};

export default connect(mapStateToProps, mapActionsToProps)(MatchRedirect);
